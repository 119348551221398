import { NavigationUrl } from '$configs/navigation';

export const WHITELIST_PAGES: string[] = [
  NavigationUrl.ROOT,
  // NavigationUrl.FAUCET,
  NavigationUrl.MARKETS,
  NavigationUrl.DASHBOARD,
  NavigationUrl.RESERVE_OVERVIEW,
  // NavigationUrl.HEALTH
  // NavigationUrl.STAKING,
];

/** @type {import('@sveltejs/kit').Reroute} */
export function reroute({ url }: { url: any }) {
  return WHITELIST_PAGES.includes(url.pathname) ? url.pathname : '/';
}
